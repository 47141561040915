import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/app/app';

import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import reducerUser from './store/reducers/user';
import reducerAlerts from './store/reducers/alerts';

import './index.scss';

const reducer = combineReducers({
  user: reducerUser,
  alerts: reducerAlerts
});
const store = createStore(reducer);

console.log = () => {}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);